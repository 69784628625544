<template>
  <div v-if="model" class="query-result">
    <div class="query-result-content">
      <p class="query-result-content-title">{{model.title}}</p>
      <section-title title="基本信息" />
      <div class="query-result-content-basis">
        <unit title="姓名" :value="model.name"></unit>
        <unit title="性别" :value="model.sex"></unit>
        <unit title="年龄" :value="model.age"></unit>
        <unit title="测评时间" :value="model.assessmentTime"></unit>
      </div>
      <section-title title="评估结果" />
      <result :model="model"></result>>
      <query-table :model="model.tableData"></query-table>
      <bar-chart :model="model.chartData"></bar-chart>
      <section-title title="指导意见" />
      <p class="query-result-content-suggestion" v-html="model.suggestion"></p>
    </div>
  </div>
</template>

<script>
  import SectionTitle from "@c/query-result/section-title.vue";
  import Unit from "@c/query-result/unit.vue";
  import Result from "@c/query-result/result.vue";
  import QueryTable from "@c/query-result/query-table.vue";
  import BarChart from "@c/query-result/bar-chart.vue";
  import { QueryDetailModel } from "./query-detail-model.js";
  export default {
    components: {
      SectionTitle,
      Unit,
      Result,
      QueryTable,
      BarChart
    },

    data() {
      return {
        model: undefined
      }
    },

    async created() {
      const id = this.$router.history.current.query.id;
      this.getAssessmentDetail(id)
    },

    methods: {
      async getAssessmentDetail(id) {
        try {
          const rawModel = await this.$api.getAssessmentDetail({ id })
          this.model = new QueryDetailModel(rawModel)
        } catch (error) {
          this.$toast.showRed(error);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .query-result {
    background-color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    &-content {
      width: 700px;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        color: black;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        margin-top: 24px;
      }

      &-basis {
        width: 100%;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
      }

      &-suggestion {
        color: black;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-line;
        width: 100%;
        padding: 12px 0;
      }
    }
  }
</style>