<template>
    <div class="query-table">
        <el-table :data="model.data" border style="width: 100%" :span-method="spanMethod"
            :highlight-current-row="false">
            <el-table-column v-for="(item,index) in model.rule" :key="index" :prop="item.prop" :label="item.label"
                :width="item.width">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {

        props: {
            model: Object
        },

        methods: {
            spanMethod({ row, column, rowIndex, columnIndex }) {
                switch (this.model.type) {
                    case QUERY_TYPE.OSA_18:
                    case QUERY_TYPE.PSQ:
                        //按纬度分
                        if (columnIndex === 0) {
                            const lastDepth = this.model.data[rowIndex - 1]?.depth
                            if (lastDepth != row.depth) {
                                var rowSpan = 0
                                this.model.data.forEach((item, index) => {
                                    if (item.depth == row.depth) {
                                        rowSpan += 1
                                    }
                                });
                                return {
                                    rowspan: rowSpan,
                                    colspan: 1
                                };
                            } else {
                                return {
                                    rowspan: 0,
                                    colspan: 0
                                };
                            }
                        }
                    default:
                        break;
                }
            }

        }

    }
</script>

<style lang="scss" scoped>
    .query-table {
        width: 100%;

        /deep/.el-table tbody tr:hover>td {
            background-color: unset !important;
        }

        /deep/.el-table tr.current-row>td.el-table__cell {
            background-color: unset !important;
        }


        /deep/.el-table {
            border-bottom: 1px solid #979797;
            border-right: 1px solid #979797;
            margin: 0 auto;
        }

        /deep/.el-table td,
        /deep/.el-table th {
            border: 1px solid #979797 !important;
            border-right: none !important;
            border-bottom: none !important;
        }

        /deep/.el-table th.el-table__cell>.cell {
            color: black;
            font-size: 14px;
            font-weight: bold;

        }

        /deep/.el-table--enable-row-transition .el-table__body td.el-table__cell {
            color: black;
            font-size: 14px;
        }
    }
</style>